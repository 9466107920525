import axios from 'axios';
import React, { useState, useRef } from 'react';
import { FaUpload, FaTimes } from 'react-icons/fa';
import { updateUserCV } from '../../features/constants/apis';
import { connect } from 'react-redux';

const FileUploadWithProgress = ({authToken, ID, onUploadSuccess}) => {
    const [uploadProgress, setUploadProgress] = useState(0);
    const [fileName, setFileName] = useState('');
    const [file, setFile] = useState(null); // Store the file object
    const [errorMessage, setErrorMessage] = useState('');
    const fileInputRef = useRef(null);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [uploadedCV, setUploadedCV] = useState('');

    const getProgressBarClass = () => {
        return uploadProgress < 100 ? 'progress-bar uploading' : 'progress-bar complete';
    };

    const allowedExtensions = /(\.pdf|\.doc|\.docx|\.png|\.jpeg)$/i;

    const handleFileChange = (e) => {   
        const maxFileSize = 5 * 1024 * 1024;

        const file = e.target.files[0];
        if (file) {
            if (file.size > maxFileSize) {
                setErrorMessage('File size exceeds 5 MB. Please select a smaller file.');
                // Reset file input and state
                setFileName('');
                setFile(null);
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
                return;
            }

            if(!allowedExtensions.exec(file.name)){
                setErrorMessage('this extension is not allowed')
                setFileName('')
                setFile(null)
                return;
            }

            setErrorMessage('');
            setFileName(file.name);
            setFile(file);
            setUploadProgress(0);

            // Simulate an upload process
            const simulateUpload = () => {
                setUploadProgress(prevProgress => {
                    if (prevProgress >= 100) {
                        clearInterval(interval);
                        return 100; // Ensure it stays at 100%
                    }
                    return prevProgress + 10;
                });
            };

            // Simulate upload every 500ms
            const interval = setInterval(simulateUpload, 300);
        }
    };

    const handleRemoveFile = () => {
        setFileName('');
        setFile(null);
        setUploadProgress(0);
        setErrorMessage('');
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const uploadRefFile = async (file) => {
        setFileUploaded(true);
        const formData = new FormData();
        formData.append('inputFile', file);
        try {
            const res = await axios.post('https://api.allpos.in/applications/upload/scopehai', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    // Update progress bar during the upload
                    setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                }
            });

            console.log(res.data.data);
            setUploadedCV(res.data.data);
            setFileUploaded(false);
            return res.data.data; // Return the uploaded CV URL
        } catch (err) {
            console.error('Error uploading file:', err);
            setFileUploaded(false);
            throw err;
        }
    };

    const handleSubmit = async () => {
        if (file) {
            try {
                const cvUrl = await uploadRefFile(file);

                const cvObject ={
                    "cv": cvUrl,
                }

                const updatedCV = await updateUserCV(ID, cvObject, authToken);
                console.log('CV updated:', updatedCV);
                console.log(cvUrl)

                onUploadSuccess();

                // alert(`File "${fileName}" uploaded and CV updated successfully!`);
                
                setUploadProgress(100); 
                setFileName('');
                setFile(null);
                setUploadProgress(0);
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
            } catch (err) {
                console.error('Error uploading file:', err);
                setUploadProgress(0);
            }
        }
    };

    
    

    return (
        <div className="upload-file-container">
            <label className="custom-file-upload">
                <input
                    type="file"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                />
                <FaUpload className="upload-icon" /> Upload CV
            </label>

            {errorMessage && (
                <p className="error-message text-danger mt-1">{errorMessage}</p>
            )}

            {fileName && !errorMessage && (
                <div className="file-details">
                    <p>File: {fileName}</p>
                    <div className="progress-container">
                        <div className="progress-bar-container">
                            <div className={getProgressBarClass()} style={{ width: `${uploadProgress}%` }}></div>
                        </div>
                        <button className="remove-file-icon" onClick={handleRemoveFile}>
                            <FaTimes />
                        </button>
                    </div>
                    {uploadProgress === 100 && (
                        <button className="submit-button" onClick={handleSubmit}>
                            Submit
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};


const mapStateToProps = ({ auth }) => {
    const { userData, allCities, role, fullName, ID, authToken } = auth

    return {
        userData,
        allCities,
        role,
        fullName,
        ID,
        authToken
    }
}

export default connect(mapStateToProps)(FileUploadWithProgress);
