import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { connect } from "react-redux";

const PaymentSuccessModal = ({ show, handleClose }) => {

    return (
        <div className='container'>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Payment Successful</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2>You have Successfully applied for VSP!</h2>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

const mapStateToProps = ({ auth }) => {
    const { userData, role, ID, authToken } = auth
    return {
        userData,
        role,
        ID,
        authToken
    }
}

export default connect(mapStateToProps)(PaymentSuccessModal)